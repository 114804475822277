import { website } from '@/axios'

export default {
  // acceptMerchent (storeId, payload) {
  //   return marketPlace().patch(`admin/stores/${storeId}`, payload)
  // },
  getImage (id) {
    return website().get(`admin/galleries/${id}`)
  },
  editImage (id, payload) {
    return website().put(`admin/galleries/${id}`, payload)
  },
  addImage (payload) {
    return website().post('admin/galleries/image', payload)
  },
  uploadImage (payload) {
    return website().post('upload', payload)
  }
}
